import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Logo from "./img/logo.svg";
import Header from './components/Header';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import OdtmLanding from './components/OdtmLanding';
import OdtmContent from './components/OdtmContent';

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      odtmData: null,
      isLoaded: false
    };
  }

  getData(lang) {
    const apiURL= (process.env.NODE_ENV === "development" ? "http://localhost" : process.env.PUBLIC_URL);
    return fetch(apiURL + '/odtmapi/wp-json/ozgunapi/v1/lang=' + lang)
    .then((response) => response.json())
    .then((responseJson) => {
      this.setState({odtmData: responseJson})
      this.setState({isLoaded: true})
    })
    .catch((error) => {
      console.error(error);
    });
  }

  componentDidMount() {
    this.setState({isLoaded: false})
    this.setState({odtmData: this.getData('tr') })
  }

  render() {
    const { error, odtmData, isLoaded } = this.state;
    if (error) {
      return <div className="odtm-went-wrong"></div>
    } else if (!isLoaded) {
        return (
          <div>
            <img className="odtm-landing-image" src={Logo} alt=""/>
            <p className="odtm-landing-title">Yükleniyor</p>
            <div className="odtm-loading">
              <div />
              <div />
            </div>
          </div>
        )
    } else {
        return <Router>
            <div>
              <Switch>
                <Route exact path='/'>
                  <OdtmPage navigation={odtmData.navigation} key="0" />
                </Route>
                {
                    odtmData.navigation.menu.map((d, i) => (
                      <Route exact path={"/" + d.link} key={i} >
                        <OdtmPage navigation={odtmData.navigation} data={odtmData[d.link]} />
                      </Route>
                    ))
                }
              </Switch>
            </div>
        </Router>
    }
  }
}

export const OdtmPage = ({ data, navigation }) => {
  if(!data) {
    return (
      <div>
        <OdtmLanding data={navigation} />
        <Footer />
      </div>
    )

  } else {
    return (
      <div>
        <Header data={data} />
        <Navigation data={navigation} />
        <OdtmContent data={data} />
        <Footer />
      </div>
    );
  }
}

export default App;