import React, { Component } from 'react';
import { MemoryRouter as Router, Redirect, Switch, Route, NavLink } from "react-router-dom";
import OdtmSubContent from '../components/OdtmSubContent';

export class OdtmContent extends Component {
  render() {
    return (
      <>
    <Router>
      <div id="odtm-content">
        <div className="container-fluid odtm-container bt-30">
          <div className="row">
            <div className="col-12">
              <div className="odtm-content-submenu">
                {
                  this.props.data.posts.map((d, i) => (
                    <NavLink key={i} activeClassName="active" to={"/" + d.link}>
                      <h2>{d.title}</h2>
                    </NavLink>
                  ))
                  }
              </div>
              <Switch>
                {
                  this.props.data.posts.map((d, i) => (
                    i === 0 ? <Redirect key={i} exact from="/" to={ d.link } /> : ""
                  ))

                }
                {
                  this.props.data.posts.map((d, i) => (
                    <Route exact key={i} path={"/" + d.link}>
                      <OdtmSubContent data={d} />
                    </Route>
                  ))
                }
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </Router>
    </>
    )
  }
}

export default OdtmContent;