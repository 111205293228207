import React, { Component } from "react";
import Logo from "../img/logo.svg";

export class OdtmLanding extends Component {
  render() {
    return (
      <>
        <nav id="menu" className="navbar navbar-default lending-navbar">
          <div className="container-fluid odtm-container odtm-container-header">
            <div className="landing-header">
              <a className="page-scroll" href={ process.env.PUBLIC_URL + "/" }>
                <img className="odtm-logo" src={Logo} alt=""/>
              </a>{" "}
            </div>
          </div>
          <div className="container-fluid odtm-container">
            <div
              style= {{
                height: "1px",
                margin: "40px 12px",
                background: "#300C46"
              }}
            />
            <p className="landing-top-text">ODTM'nin sizlere sunduğu hizmet dünyasına hoşgeldiniz!</p>
            <div
              style= {{
                marginBottom: "40px"
              }}
            />
              {
                this.props.data
                  ? this.props.data.menu.map((d, i) => (
                    <a href={process.env.PUBLIC_URL + '/' + d.link} key={i}>
                      <div className="col-xs-6 col-md-3">
                        <div className="landing-header-column">
                          <img className="landing-header-image" src={d.landingImage} alt="MainImage" style={{
                            width: '100%',
                            height: 'auto'
                          }} />
                          <div className="landing-header-column-down">
                            <div 
                              style={{ 
                                  backgroundImage: `url(${d.landingLogo})`,
                                  width: "56px",
                                  height: "56px"
                              }}
                            ></div>
                            <h1 className="landing-header-column-down-text">{d.title} </h1>
                          </div>
                        </div>
                      </div>
                    </a>
                  ))
                : "loading"
              }
            </div>
        </nav>
      </>
    );
  }
}

export default OdtmLanding;