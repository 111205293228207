import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../img/logo.svg";
import MenuItem from '../components/MenuItem';

export class Navigation extends Component {
  render() {
    return (
      <>
        <nav id="menu" className="navbar navbar-default navbar-fixed-top">
          <div className="container-fluid odtm-container odtm-container-header">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
              >
                {" "}
                <span className="sr-only">Toggle navigation</span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
              </button>
              <a className="page-scroll" href={ process.env.PUBLIC_URL + "/" }>
                <img className="odtm-logo-navigation" src={Logo} alt=""/>
              </a>{" "}
            </div>

            <div
              className="collapse navbar-collapse navigation-width"
              id="bs-example-navbar-collapse-1"
            >
              <div className="odtm-navbar">
              {this.props.data
                ? this.props.data.menu.map((d, i) => (
                    <NavLink key={i} activeClassName="odmt-landing-menu-item-active" to={"/" + d.link}>
                      <MenuItem itemLink={process.env.PUBLIC_URL + '/' + d.link} text={d.title} iconSize="32px" backgroundImage={d.menuLogo} />
                    </NavLink>
                  ))
                : "loading"}
              </div>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

export default Navigation;