import React from 'react';

const defaultProps = {
  tag: 'div',
  color: '',
  size: '',
  loading: false,
  wide: false,
  wideMobile: false,
  disabled: false
}

const MenuItem = ({
  tag,
  disabled,
  text,
  backgroundImage,
  iconSize,
  itemLink,
  ...props
}) => {

  const Component = tag;
  return (
    <div className="odmt-landing-menu-item">
        <Component
          {...props}
          style={
            { 
              WebkitMaskImage: `url(${backgroundImage})`,
              margin: "auto",
              width: iconSize,
              height: iconSize
            }
          }
          className="MenuItem"
          disabled={disabled}
        />
        <p className="odtm-nav-item-title">{text}</p>
        <div className="odtm-nav-item-active-line"></div>
    </div>
  );
}

MenuItem.defaultProps = defaultProps;

export default MenuItem;