import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export class OdtmSubContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }
  render() {
    const { photoIndex, isOpen } = this.state;
    const images_thumbnail = this.props.data.gallery_thumbnail;
    const images_fullsize = this.props.data.gallery_fullsize;
    return (
        <>
          <div className="odtm-content-text">
              {
                this.props.data.contact
                  ? 
                    <div className="odtm-contact">

                      { 
                        this.props.data.contact.address ? 
                          <div className="contact-info-item">
                            <div className="pin-icon" />
                            <p className="contact-info-text">{this.props.data.contact.address}</p>
                          </div>
                      : ""
                      }
                      { 
                        this.props.data.contact.phone1 ? 
                          <div className="contact-info-item">
                            <div className="phone-icon" />
                            <a href={"tel:" + this.props.data.contact.phone1}>
                            <p className="contact-info-text">{this.props.data.contact.phone1}</p>
                            </a>
                          </div>
                      : ""
                      }
                      { 
                        this.props.data.contact.phone2 ? 
                          <div className="contact-info-item">
                            <div className="phone-icon" />
                            <a href={"tel:" + this.props.data.contact.phone2}>
                            <p className="contact-info-text">{this.props.data.contact.phone2}</p>
                            </a>
                          </div>
                      : ""
                      }
                      { 
                        this.props.data.contact.phone3 ? 
                          <div className="contact-info-item">
                            <div className="phone-icon" />
                            <a href={"tel:" + this.props.data.contact.phone3}>
                            <p className="contact-info-text">{this.props.data.contact.phone3}</p>
                            </a>
                          </div>
                      : ""
                      }
                      { 
                        this.props.data.contact.email ? 
                          <div className="contact-info-item">
                            <div className="email-icon" />
                            <a href={"mailto:" + this.props.data.contact.email}>
                            <p className="contact-info-text">{this.props.data.contact.email}</p>
                            </a>
                          </div>
                      : ""
                      }
                      { 
                        this.props.data.contact.facebook ? 
                          <div className="social-info-item">
                            <a href={this.props.data.contact.facebook}>
                              <div className="facebook-icon" />
                            </a>
                          </div>
                        : ""
                      }
                      {
                        this.props.data.contact.instagram ? 
                          <div className="social-info-item">
                            <a href={this.props.data.contact.instagram}>
                              <div className="instagram-icon" />
                            </a>
                          </div>
                        : ""
                      }
                      {
                        this.props.data.contact.linkedin ? 
                          <div className="social-info-item">
                            <a href={this.props.data.contact.linkedin}>
                            <div className="linkedin-icon" />
                            </a>
                          </div>
                        : ""
                      }
                    </div>
                : 
                <p>{this.props.data.content}</p>
              }
          </div>
          <div className="gallery">
            <div className="row">
              <div className="col-xs-12 float-center">
                {
                  images_thumbnail ? images_thumbnail.map((d, i) => (
                      <div 
                        key={i}
                        onClick={ () => this.setState({ isOpen: true, photoIndex: i }) }
                        className="gallery-item-image"
                        style={
                          {
                            background: `url(${d}) center center no-repeat`,
                            backgroundSize: "cover",
                            display: "inline-grid",
                            width: "96px",
                            height: "96px"
                          }
                        }
                        width="96"
                        height="96"
                        alt={"gallery-item-" + i} />
                  )) : ""
                }

                {isOpen && (
                  <Lightbox
                    mainSrc={images_fullsize[photoIndex]}
                    nextSrc={images_fullsize[(photoIndex + 1) % images_fullsize.length]}
                    prevSrc={images_fullsize[(photoIndex + images_fullsize.length - 1) % images_fullsize.length]}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + images_fullsize.length - 1) % images_fullsize.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + 1) % images_fullsize.length,
                      })
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </>
    )
  }
}

export default OdtmSubContent