import React, { Component } from "react";

export class Header extends Component {
  
  render() {
    const urlString = `linear-gradient(to bottom, rgba(0, 0, 0, .40), rgba(0, 0, 0, .40)),url('${this.props.data.main_content.image}')`;
    const IntroStyle = {
      backgroundImage: urlString,
      backgroundSize: "cover"
    }
    return (
      <header id="header">
        <div style={IntroStyle} className="intro">
          <div className="overlay">
            <div className="container-fluid odtm-container">
                <div className="intro-text">
                  <div className="intro-content-box">
                    <h2>
                      <img className="intro-content-image" src={this.props.data.main_content.introImage} alt="OdtmIntroImage" />
                      {this.props.data.main_content.title}
                    </h2>
                    <div className="intro-content-line" />
                    <p>
                    {this.props.data.main_content.content}
                    </p>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div className="header-separator" />
      </header>
    );
  }
}

export default Header;