import React, { Component } from "react";

export class Footer extends Component {
    render() {
        return (
            <div id="footer">
                <div className="row footer-container">
                    <div className="footer-social-icons col-xs-4 col-md-4">
                        <p>&copy; 2021 ODTM</p>
                    </div>
                    <div className="footer-lang-icons col-xs-4 col-md-4">
                        <a href={process.env.PUBLIC_URL}>
                            <img src="/img/turkish.svg" className="footer-icon" alt="Turkish Language" />
                        </a>
                        <a href={process.env.PUBLIC_URL}>
                            <img src="/img/english.svg" className="footer-icon" alt="English Language" />
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;